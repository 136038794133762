import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import {Context} from '../../AppContext';
import {Steps, Result, message, Alert} from 'antd';
import {
  Button,
  Input,
  Heading,
  Row,
  Label,
  Text,
  ErrorHint,
  LinkButton,
} from '../../Widgets';
import {formatValidator} from '../../Utils';
import LoginModal, {metadata as modalMetadata} from '../../Modals/Login';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import {ErrRegister} from '../../errors';
import {navigate} from 'gatsby';
import AddressField from '../../Components/AddressField';

const queryString = require('query-string');
const appConfig = require('../../data.json');

const {isNotEmpty, isEmail, isNumeric} = formatValidator;

const TIME_RESET = 120; //s

const UISTATE = {
  valid_email: 0,
  validating: 1,
  registering: 2,
  registered: 3,
};
const TYPE = {
  normal: 'normal',
  enterprise: 'enterprise',
};
const steps = ['驗證信箱', '點擊驗證信', '輸入密碼', '完成註冊'];

function errorHandler(err) {
  console.warn(err);
  if (err instanceof ErrRegister) {
    message.warning(err.message);
  } else if (err && err.error === 'user_exists') {
    message.warning('此信箱已有存在帳號！');
  } else if (err && err.error === 'user_exist') {
    message.warning('此信箱已有存在帳號！');
  } else if (err && err.error === 'invalid_token') {
    message.warning('此連結已過期，請重新寄送驗證信');
  } else if (
    err &&
    err.error === 'invalid_jwt' &&
    err.detail === 'jwt decode error'
  ) {
    message.warning('此連結已過期，請重新寄送驗證信');
  } else if (err && err.detail && err.detail.indexOf('last') >= 0) {
    message.warning(`${TIME_RESET}秒內，無法再次寄送驗證信`);
  } else {
    message.error('發生錯誤');
  }
}

export default function Register({location}) {
  const [uiState, setUiState] = useState(UISTATE.valid_email);
  const [type, setType] = useState(TYPE.normal);
  const [values, setValues] = useState({
    email: '',
    password: '',
    password2: '',
  });
  const [entData, _setEntData] = useState({
    ent_name: '',
    contact_name: '',
    gui_number: '',
    ent_tel: '',
    tel_ext: '',
    ent_zip_code: '',
    ent_city: '',
    ent_district: '',
    ent_address: '',
  });
  const [validate, setValidate] = useState(false);

  const [access_token, setAccessToken] = useState();
  const [time, setTime] = useState(0);
  const timer = useRef();

  const app = useContext(Context);
  const {profile} = app.state;

  useEffect(() => {
    if (profile) {
      navigate('/profile');
    }
  }, [profile]);

  useEffect(() => {
    (async () => {
      const parsed = queryString.parse(location.search);
      const {access_token} = parsed; //validation_token
      if (access_token) {
        setAccessToken(access_token);
        setUiState(UISTATE.registering);
        app.actions.setLoading(false);
      }

      let isEnt = await app.actions.isEnterpriseRegister();
      setType(isEnt ? TYPE.enterprise : TYPE.normal);
    })();
  }, [location, app.actions]);

  useEffect(() => {
    if (time === TIME_RESET) {
      if (timer.current) {
        clearInterval(timer.current);
      }

      timer.current = setInterval(() => {
        if (time <= 0) {
          clearInterval(timer.current);
        }

        setTime((pre) => pre - 1);
      }, 1000);
    }

    if (time === 0) {
      return () => clearInterval(timer.current);
    }
  }, [time]);

  const valid = () => {
    if (!isNotEmpty(values.password) || !isNotEmpty(values.password2)) {
      throw new ErrRegister('密碼不可為空白');
    }

    if (values.password !== values.password2) {
      throw new ErrRegister('密碼需要確認密碼一致');
    }
  };

  const register = async () => {
    app.actions.setLoading(true);

    try {
      valid();

      await app.actions.register({
        access_token,
        password: values.password,
      });

      setUiState(UISTATE.registered);
    } catch (ex) {
      errorHandler(ex);
    }
    app.actions.setLoading(false);
  };

  const enterpriseRegister = async () => {
    app.actions.setLoading(true);

    try {
      setValidate(true);
      valid();

      if (
        !isNotEmpty(entData.ent_name) ||
        !isNotEmpty(entData.gui_number) ||
        !isNotEmpty(entData.ent_tel) ||
        !isNotEmpty(entData.ent_address) ||
        !isNotEmpty(entData.ent_city) ||
        !isNotEmpty(entData.ent_district) ||
        !isNotEmpty(entData.contact_name)
      ) {
        throw new ErrRegister('欄位不能為空');
      } else if (
        !isNumeric(entData.gui_number) ||
        !isNumeric(entData.ent_tel)
      ) {
        throw new ErrRegister('格式錯誤');
      }

      await app.actions.enterpriseRegister({
        access_token,
        password: values.password,
        ...entData,
      });

      setUiState(UISTATE.registered);
    } catch (ex) {
      errorHandler(ex);
    }
    app.actions.setLoading(false);
  };

  const validEmail = async () => {
    app.actions.setLoading(true);

    try {
      if (!isNotEmpty(values.email)) {
        throw new ErrRegister('信箱不可為空白');
      }

      if (!isEmail(values.email)) {
        throw new ErrRegister('信箱格式錯誤');
      }

      await app.actions.validEmail({
        email: values.email,
      });
      setTime(TIME_RESET);
      setUiState(UISTATE.validating);
    } catch (ex) {
      errorHandler(ex);
    }

    app.actions.setLoading(false);
  };

  const setEntData = useCallback(
    (obj) => _setEntData((prev) => ({...prev, ...obj})),
    [],
  );

  return (
    <ContentWrapper>
      <div style={{maxWidth: 600, margin: '0 auto'}}>
        <Heading size="lg" align="center">
          註冊
        </Heading>
        <Steps current={uiState} style={{marginBottom: 30}}>
          {steps.map((step, idx) => (
            <Steps.Step
              key={step}
              title={
                <Text
                  size="lg"
                  weight={500}
                  color={
                    uiState === idx
                      ? appConfig.colors.text
                      : appConfig.colors.textThird
                  }>
                  {step}
                </Text>
              }
            />
          ))}
        </Steps>

        {(() => {
          switch (uiState) {
            case UISTATE.valid_email:
              return (
                <>
                  <Heading>驗證信箱</Heading>

                  <div>
                    <Label>電子信箱</Label>
                    <Input
                      id="register-email-input"
                      value={values.email}
                      onChange={(e) => {
                        let value = e.target.value;
                        setValues((prev) => ({...prev, email: value}));
                      }}
                    />
                  </div>

                  <Row margin="20px 0" style={{justifyContent: 'flex-end'}}>
                    <Button disabled={time > 0} onClick={() => validEmail()}>
                      寄送驗證信
                    </Button>
                  </Row>
                </>
              );
            case UISTATE.validating:
              return (
                <>
                  <Heading>已寄送驗證信</Heading>
                  <div>
                    <Label>電子信箱</Label>
                    <Input
                      id="register-email-input"
                      value={values.email}
                      onChange={(e) => {
                        let value = e.target.value;
                        setValues((prev) => ({...prev, email: value}));
                      }}
                      style={{margin: 0}}
                    />
                  </div>
                  <div style={{marginBottom: 10, color: '#999'}}>
                    請至信箱 {values.email}{' '}
                    查看是否收到驗證信，若沒有收到驗證信，可點選按鈕再次寄出。{' '}
                  </div>
                  <Alert
                    message="收到驗證信後，請點選「連結」跳回註冊頁，完成註冊程序"
                    type="info"
                    style={{margin: '10px 0'}}
                  />
                  <Row margin="20px 0" style={{justifyContent: 'flex-end'}}>
                    {time > 0 && (
                      <div style={{color: '#999', padding: 15}}>
                        {time} 秒後，可重寄驗證信
                      </div>
                    )}
                    <Button disabled={time > 0} onClick={() => validEmail()}>
                      寄送驗證信
                    </Button>
                  </Row>
                </>
              );
            case UISTATE.registering:
              return (
                <>
                  <h2>輸入密碼</h2>
                  <div>
                    <Label>密碼</Label>
                    <Input
                      id="register-password-input"
                      type="password"
                      value={values.password}
                      onChange={(e) => {
                        let value = e.target.value;
                        setValues((prev) => ({...prev, password: value}));
                      }}
                    />
                  </div>

                  <div>
                    <Label>確認密碼</Label>
                    <Input
                      id="register-password-input-confirm"
                      type="password"
                      value={values.password2}
                      onChange={(e) => {
                        let value = e.target.value;
                        setValues((prev) => ({
                          ...prev,
                          password2: value,
                        }));
                      }}
                    />
                  </div>

                  {type === TYPE.enterprise && (
                    <React.Fragment>
                      <div>
                        <Label flex="0 0 70px">公司名稱*</Label>
                        <Input
                          value={entData.ent_name}
                          placeholder="公司名稱"
                          onChange={(e) => {
                            let value = e.target.value;
                            setEntData({ent_name: value});
                          }}
                        />
                        {validate && !isNotEmpty(entData.ent_name) && (
                          <ErrorHint>公司名稱不能為空</ErrorHint>
                        )}
                      </div>

                      <div>
                        <Label flex="0 0 70px">統一編號*</Label>
                        <Input
                          value={entData.gui_number || ''}
                          placeholder="統一編號"
                          onChange={(e) =>
                            setEntData({gui_number: e.target.value})
                          }
                        />
                        {validate && !isNotEmpty(entData.gui_number) && (
                          <ErrorHint>統一編號不能為空</ErrorHint>
                        )}
                      </div>

                      <div>
                        <Label flex="0 0 70px">聯絡人名稱*</Label>
                        <Input
                          value={entData.contact_name || ''}
                          placeholder="聯絡人名稱"
                          onChange={(e) =>
                            setEntData({contact_name: e.target.value})
                          }
                        />
                        {validate && !isNotEmpty(entData.contact_name) && (
                          <ErrorHint>聯絡人名稱不能為空</ErrorHint>
                        )}
                      </div>

                      <div>
                        <Label flex="0 0 70px">公司電話*</Label>
                        <Row>
                          <Input
                            value={entData.ent_tel || ''}
                            placeholder="公司電話"
                            onChange={(e) =>
                              setEntData({ent_tel: e.target.value})
                            }
                          />
                          <Input
                            placeholder="分機"
                            type="short"
                            value={entData.tel_ext || ''}
                            onChange={(e) =>
                              setEntData({tel_ext: e.target.value})
                            }
                          />

                          {validate && !isNotEmpty(entData.ent_tel) ? (
                            <ErrorHint>公司電話不能為空</ErrorHint>
                          ) : validate && !isNumeric(entData.ent_tel) ? (
                            <ErrorHint>請確認公司電話格式</ErrorHint>
                          ) : null}
                        </Row>
                      </div>
                      <div>
                        <Label flex="0 0 70px">公司地址*</Label>
                        <AddressField
                          zip_code={entData.ent_zip_code}
                          city={entData.ent_city}
                          district={entData.ent_district}
                          address={entData.ent_address}
                          onChange={(obj) =>
                            setEntData({
                              ...(obj.zip_code !== undefined && {
                                ent_zip_code: obj.zip_code,
                              }),
                              ...(obj.city !== undefined && {
                                ent_city: obj.city,
                              }),
                              ...(obj.district !== undefined && {
                                ent_district: obj.district,
                              }),
                              ...(obj.address !== undefined && {
                                ent_address: obj.address,
                              }),
                            })
                          }
                        />

                        {validate && !isNotEmpty(entData.ent_address) && (
                          <ErrorHint>公司地址不能為空</ErrorHint>
                        )}
                        {validate &&
                          (!isNotEmpty(entData.ent_city) ||
                            !isNotEmpty(entData.ent_district)) && (
                            <ErrorHint>縣市鄉鎮不能為空</ErrorHint>
                          )}
                      </div>
                    </React.Fragment>
                  )}

                  <Row margin="20px 0">
                    <Button
                      type="default"
                      onClick={() => setUiState(UISTATE.validating)}>
                      上一步{' '}
                    </Button>
                    <div style={{flex: 1}} />
                    <Button
                      onClick={
                        type === TYPE.normal ? register : enterpriseRegister
                      }>
                      註冊{' '}
                    </Button>
                  </Row>
                  {type === TYPE.normal ? (
                    <Row margin="20px 0" style={{justifyContent: 'flex-end'}}>
                      <Text size="sm">不是一般會員？</Text>
                      <LinkButton
                        onClick={(e) => {
                          e.preventDefault();
                          setType(TYPE.enterprise);
                          app.actions.setEnterpriseRegister(true);
                        }}>
                        切換成企業會員註冊
                      </LinkButton>
                    </Row>
                  ) : (
                    <Row margin="20px 0" style={{justifyContent: 'flex-end'}}>
                      <Text size="sm">不是企業會員？</Text>
                      <LinkButton
                        onClick={(e) => {
                          e.preventDefault();
                          setType(TYPE.normal);
                          app.actions.setEnterpriseRegister(false);
                        }}>
                        切換成一般會員註冊
                      </LinkButton>
                    </Row>
                  )}
                </>
              );
            case UISTATE.registered:
              return (
                <Result
                  title="註冊成功!"
                  subTitle={<Text>請登入，並前往會員中心填寫會員基本資料</Text>}
                  status="success"
                  extra={[
                    <Button
                      onClick={() => {
                        app.actions.setModal({
                          content: <LoginModal />,
                          ...modalMetadata,
                        });
                      }}>
                      登入
                    </Button>,
                  ]}
                />
              );
            default:
              return null;
          }
        })()}
      </div>
    </ContentWrapper>
  );
}
