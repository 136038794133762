import React, {useContext, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Context} from '../AppContext';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {Input, Select, SelectOption} from '../Widgets';

export default function AddressField(props) {
  const {
    onChange,
    zip_code = null,
    city = null,
    district = null,
    address = '',
    disabled = false,
  } = props;

  const app = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);

  const getZipCode = useCallback(
    async (zip_code) => {
      setLoading(true);
      try {
        let resp = await app.actions.getZipCode(zip_code);
        if (resp) {
          onChange({
            zip_code,
            city: resp.countyName,
            district: resp.townName,
          });
        }
      } catch (err) {
        console.warn(err);
      }
      setLoading(false);
    },
    [app.actions, onChange],
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let resp = await app.actions.getCities();
        setCities(resp);
      } catch (err) {
        console.warn(err);
      }
      setLoading(false);
    })();
  }, [app.actions]);

  const getDistricts = useCallback(async () => {
    if (city) {
      setLoading(true);
      try {
        let resp = await app.actions.getDistricts(city);
        setDistricts(resp);
      } catch (err) {
        console.warn(err);
      }
      setLoading(false);
    }
  }, [app.actions, city]);

  useEffect(() => {
    getDistricts();
  }, [getDistricts]);

  return (
    <Wrapper>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{fontSize: 16}} />}
        style={{
          alignSelf: 'center',
          marginRight: 10,
          marginBottom: 10,
          flexBasis: 50,
        }}
      />
      <Input
        placeholder="郵遞區號"
        disabled={loading || disabled}
        value={zip_code}
        onChange={(e) => {
          let value = e.target.value;
          onChange({zip_code: value});
        }}
        onBlur={() => {
          if (/[0-9]{3}/g.test(zip_code)) {
            getZipCode(zip_code);
          }
        }}
        type="short"
        style={{marginRight: 10, marginBottom: 10}}
      />

      <Select
        placeholder="縣市"
        value={city || ''}
        onChange={(value) => {
          onChange({
            city: value,
            district: null,
            zip_code: null,
          });
        }}
        disabled={loading || disabled}
        style={{marginRight: 10, marginBottom: 10, flexBasis: 130}}>
        <SelectOption value="" key="empty" disabled>
          縣市
        </SelectOption>
        {cities.map((c) => (
          <SelectOption key={c.countyName} value={c.countyName}>
            {c.countyName}
          </SelectOption>
        ))}
      </Select>

      <Select
        placeholder="鄉鎮市區"
        value={district || ''}
        onChange={(value) => {
          onChange({district: value});

          // set zip_code
          let instance = districts.find((t) => t.townName === value);

          if (instance) {
            onChange({
              district: value,
              zip_code: instance.zipCode,
            });
          }
        }}
        disabled={loading || disabled}
        style={{marginRight: 10, marginBottom: 10, flexBasis: 130}}>
        <SelectOption value="" key="empty" disabled>
          鄉鎮市區
        </SelectOption>
        {districts.map((t) => (
          <SelectOption key={t.townName} value={t.townName}>
            {t.townName}
          </SelectOption>
        ))}
      </Select>

      <Input
        placeholder="詳細地址路段"
        className="address"
        disabled={loading || disabled}
        value={address}
        onChange={(e) => onChange({address: e.target.value})}
        type="long"
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center-ms-inline-grid;
  flex-wrap: wrap;

  & > .address {
    flex-basis: 380px;
  }
`;
